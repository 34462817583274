/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import uniqid from 'uniqid';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import DefaultPrinters from '../../data/printer.json';
import DefaultShipperSettings from '../../data/shipper-settings.json';
import QRCodeTamplate from '../../data/qr-code-tamplate.json'
import { handleApiError, isViewer } from '../../helpers';
import ReactGA from "react-ga4"

const getShipperSettingsQuery = /* GraphQL */ `
  query GetShipperSettings($shipperId: ID!) {
    shipperSettingsByShipperId(shipperId: $shipperId) {
      items {
        id
        key
		name
		value
		type
      }
    }
  }
`;

const createShipperSettingMutation = /* GraphQL */ `
  mutation CreateShipperSetting ($input: CreateShipperSettingInput!) {
    createShipperSetting(input: $input) {
      id
    }
  }
`;

const updateShipperSettingMutation = /* GraphQL */ `
  mutation UpdateShipperSetting ($input: UpdateShipperSettingInput!) {
    updateShipperSetting(input: $input) {
      id
    }
  }
`;

const deleteShipperSettingMutation = /* GraphQL */ `
  mutation DeleteShipperSetting ($input: DeleteShipperSettingInput!) {
    deleteShipperSetting(input: $input) {
      id
    }
  }
`;

const ShipperSettings = () => {

	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myUserRole = useSelector((state) => state.slice.USER.role)
	const { shipperGroupId, shipperId } = useParams();

	const menus = [
		{ 'name': 'Overview', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/overview`, 'active': false },
		{ 'name': 'Settings', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/setting`, 'active': true },
		{ 'name': 'Services', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/service`, 'active': false },
		{ 'name': 'Products', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/product`, 'active': false },
	]

	const [spinner, showSpinner] = useState(false);
	const [errors, setErrors] = useState({});
	const [setting, setSetting] = useState({});
	const [settings, setSettings] = useState([]);
	const [settingModal, showSettingModal] = useState();

	useEffect(() => {
		getShipperSettings()
		ReactGA.send({
			hitType: "pageview",
			page: `/shipper-group/${shipperGroupId}/shipper/${shipperId}/setting`,
		})

	}, [])

	const getShipperSettings = () => {
		showSpinner(true)
		API.graphql({ query: getShipperSettingsQuery, variables: { shipperId } })
			.then(({ data }) => {
				const items = data?.shipperSettingsByShipperId?.items.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }))
				setSettings(items)
			})
			.catch((error) => handleApiError(error))
			.finally(() => showSpinner(false))

	}

	const handleSettingSelect = (e) => {
		const index = DefaultShipperSettings.findIndex(x => x.key === e.target.value);
		const defaultSetting = DefaultShipperSettings[index];
		setSetting(defaultSetting);
	}

	const isFormValid = () => {
		let errors = {}

		if (!setting.key || setting.key === '-') errors.key = 'Setting is required';
		if (!setting.value || setting.value === '-') errors.value = 'Value is required';

		setErrors(errors);
		return Object.keys(errors).length > 0 ? false : true
	}

	const addUpdateSetting = () => {
		if (isFormValid()) {
			showSpinner(true)
			if (setting?.id) {
				API.graphql({ query: updateShipperSettingMutation, variables: { input: setting } })
					.then(() => {
						toast.success('Setting has been updated');
						getShipperSettings();
						showSettingModal(false);
					})
			} else {
				let settingIndex = DefaultShipperSettings.findIndex(x => x.key === setting.key);
				let defaultSetting = DefaultShipperSettings[settingIndex];

				let input = {
					id: uniqid(),
					shipperId: shipperId,
					key: setting.key,
					name: defaultSetting.name,
					value: setting.value,
					type: defaultSetting.type
				}

				API.graphql({ query: createShipperSettingMutation, variables: { input: input } })
					.then(() => {
						toast.success('Setting has been added');
						getShipperSettings();
						showSettingModal(false);
					}).catch(() => {
						toast.error('Oops something went wrong')
						showSettingModal(false);
						showSpinner(false)
					})
			}
		}
	}

	const removeSetting = (id) => {
		swal({
			text: 'Are you sure that you want to remove this setting?',
			buttons: ['Cancel', 'Remove'],
			dangerMode: true,
		}).then(status => {
			if (status) {
				showSpinner(true)
				API.graphql({ query: deleteShipperSettingMutation, variables: { input: { id: id } } })
					.then((data) => {
						toast.success('Setting has been removed')
						getShipperSettings()
					})
			}
		})
	}

	return (
		<>
			<PageHeader title={myShipper?.name} name='Settings' pageTitle={"Shipper Settings"} menus={menus} image={myShipper?.image}>
				{!isViewer(myUserRole) && <button className='btn btn-dark' onClick={() => { setSetting({}); showSettingModal(true); }}>New Setting</button>}
			</PageHeader>

			<Container fluid>
				<Spinner display={spinner}>
					<Card>
						<Card.Body className='py-0'>
							<ListGroup className='list-group-flush'>
								{
									settings && settings.length > 0 && settings.map((setting, index) => {
										return (
											<ListGroupItem key={index}>
												<Row>
													<Col>
														{setting.name}
													</Col>
													<Col className='col-auto text-muted'>
														{setting.type === 'PRINTER' ? DefaultPrinters[setting.value] : setting.value}
													</Col>
													{!isViewer(myUserRole) &&
														< Col className='col-auto'>
															<button className='btn btn-sm btn-light' onClick={() => { setSetting(setting); showSettingModal(true); }}>
																<i className='fe fe-edit-2' />
															</button>
															<button className='btn btn-sm btn-light ms-2' onClick={() => removeSetting(setting?.id)}>
																<i className='fe fe-trash' />
															</button>
														</Col>}
												</Row>
											</ListGroupItem>
										)
									})
								}
							</ListGroup>
						</Card.Body>
					</Card>
				</Spinner>
			</Container >

			<Modal show={settingModal}>
				<Modal.Header>
					<Modal.Title>
						{setting?.id ? `${setting?.name}` : `New Setting`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						setting?.id ? <></> : <Form.Group className='mb-3'>
							<Form.Select name='key' defaultValue={setting?.key || '-'} onChange={handleSettingSelect} isInvalid={!!errors?.key}>
								<option value='-' disabled>Select a setting</option>
								{
									DefaultShipperSettings.map((item, index) =>
										<option key={index} value={item?.key} disabled={settings.findIndex(x => x.key === item.key) >= 0}>
											{item?.name}
										</option>
									)
								}
							</Form.Select>
							<Form.Control.Feedback type='invalid'>{errors?.key}</Form.Control.Feedback>
						</Form.Group>
					}

					{
						setting?.type === 'PRINTER' && <Form.Group>
							<Form.Select defaultValue={setting?.value || '-'} onChange={(e) => setSetting({ ...setting, value: e.target.value })} isInvalid={!!errors?.value}>
								<option value='-' disabled>Select a Printer</option>
								{
									Object.keys(DefaultPrinters).map((key) => <option value={key}>{DefaultPrinters[key]}</option>)
								}
							</Form.Select>
							<Form.Control.Feedback type='invalid'>{errors?.value}</Form.Control.Feedback>
						</Form.Group>
					}
					{
						setting?.type === 'QR_TEMPLATE' && <Form.Group>
							<Form.Select defaultValue={setting?.value || '-'} onChange={(e) => setSetting({ ...setting, value: e.target.value })} isInvalid={!!errors?.value}>
								<option value='-' disabled>Select a Qr Tamplate</option>
								{
									Object.keys(QRCodeTamplate).map((key) => <option value={key}>{QRCodeTamplate[key]}</option>)
								}
							</Form.Select>
							<Form.Control.Feedback type='invalid'>{errors?.value}</Form.Control.Feedback>
						</Form.Group>
					}

					{
						setting?.type === 'TEXT' && <Form.Group>
							<Form.Control type='text' value={setting?.value} onChange={(e) => setSetting({ ...setting, value: e.target.value })} isInvalid={!!errors?.value} />
							<Form.Control.Feedback type='invalid'>{errors?.value}</Form.Control.Feedback>
						</Form.Group>
					}

					{
						setting?.type === 'EMAIL' && <Form.Group>
							<Form.Control type='text' value={setting?.value} onChange={(e) => setSetting({ ...setting, value: e.target.value })} isInvalid={!!errors?.value} />
							<Form.Control.Feedback type='invalid'>{errors?.value}</Form.Control.Feedback>
						</Form.Group>
					}

					{
						setting?.type === 'URL' && <Form.Group>
							<Form.Control type='text' value={setting?.value} onChange={(e) => setSetting({ ...setting, value: e.target.value })} isInvalid={!!errors?.value} />
							<Form.Control.Feedback type='invalid'>{errors?.value}</Form.Control.Feedback>
						</Form.Group>
					}

					{
						setting?.type === 'BOOLEAN' && <div>
							<button className={`btn ${setting.value === 'Yes' ? 'btn-dark' : 'btn-light'} me-2`} onClick={() => setSetting({ ...setting, value: 'Yes' })}>Yes</button>
							<button className={`btn ${setting.value === 'No' ? 'btn-dark' : 'btn-light'} me-2`} onClick={() => setSetting({ ...setting, value: 'No' })}>No</button>
						</div>
					}
				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => addUpdateSetting()}>Continue</button>
						<button className='btn btn-link text-muted' onClick={() => showSettingModal(false)} >Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ShipperSettings;