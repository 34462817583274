/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { ElasticFacetFilter } from '../../components/ElasticFilter';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { UserRoleBadge } from '../../components/StatusBadge';
import { PatientActiveFilter, TableClearFilter, TableKeywordSearch, TablePagination } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, handleApiError, isOwner, toLocalDate, toLocalTime, toTitleCase } from '../../helpers';
import ReactGA from "react-ga4"

const resetUserPassword = /* GraphQL */ `
  mutation ResetUserPassword($id: ID!) {
    resetUserPassword(id: $id)
  }
`;

const UserList = () => {

	const navigate = useNavigate();
	const myUserRole = useSelector((state) => state.slice.USER.role)

	const [users, setUsers] = useState([]);
	const [facets, setFacets] = useState({})
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [spinner, showSpinner] = useState(true);

	const [keyword, setKeyword] = useState('')
	const [active, setActive] = useState("")
	const [shipperGroup, setShipperGroup] = useState()
	const [shipper, setShipper] = useState()
	const [carrier, setCarrier] = useState()
	const [role, setRole] = useState();


	

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/user",
		})
	}, [])

	useEffect(() => {
		const delay = setTimeout(() => searchUser(), DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword, active, role, shipperGroup, shipper, carrier, pageNumber]);

	useEffect(() => {
		setPageNumber(0)
	}, [keyword, active, role, shipperGroup, shipper, carrier]);

	const searchUser = async () => {
		showSpinner(true)

		try {
			const apiName = 'api';
			const path = `/search/user?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;

			let search = {
				body: {
					aggs: {
						"shipperGroups": {
							"terms": {
								"field": "shipperGroups.shipperGroup.name.keyword",
								"size": 1000
							}
						},
						"shipper": {
							"terms": {
								"field": "shippers.shipper.name.keyword",
								"size": 1000
							}
						},
						"carrier": {
							"terms": {
								"field": "carriers.carrier.name.keyword",
								"size": 1000
							}
						},
						"role": {
							"terms": {
								"field": "role.keyword",
								"size": 1000
							}
						},
					},
					sort: [
						{ "_score": { "order": "desc" } },
						{ "name.keyword": { "order": "asc" } }
					],
					query: {
						bool: {
							must: [],
						}
					}

				}
			};

			if (keyword) {
				if (keyword.includes('@')) {
					search.body.query.bool.must.push(
						{
							"multi_match": {
								"query": keyword,
								"fields": ["email"]
							}
						}
					)
				} else {
					search.body.query.bool.must.push(
						{
							"multi_match": {
								"query": keyword,
								"fields": ["name"]
							}
						}
					)
				}

			}

			if (shipperGroup) search.body.query.bool.must.push({ match: { "shipperGroups.shipperGroup.name.keyword": shipperGroup } })
			if (shipper) search.body.query.bool.must.push({ match: { "shippers.shipper.name.keyword": shipper } })
			if (carrier) search.body.query.bool.must.push({ match: { "carriers.carrier.name.keyword": carrier } })
			if (role) search.body.query.bool.must.push({ match: { "role": role } })
			if (active === true) search.body.query.bool.must.push({ match: { active: active } })
			else if (active === false) search.body.query.bool.must.push({ match: { active: active } })

			const data = await API.post(apiName, path, search);
			setFacets(data.aggregations)
			setUsers(data.hits.hits);
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			showSpinner(false);
		} catch (error) {
			showSpinner(false);
			handleApiError(error)
		}
	}

	const clearFilters = () => {
		setShipperGroup();
		setShipper();
		setCarrier()
		setKeyword('');
		setPageNumber(0);
		setActive('')
		setRole(null)
	};


	const resetPassword = (user) => {
		swal({
			text: `Are you certain you want to reset ${user?.name} password?`,
			buttons: ['No', 'Yes'],
			icon: 'error',
			dangerMode: true,
		}).then(async status => {
			if (status) {
				showSpinner(true)
				try {
					await API.graphql({ query: resetUserPassword, variables: { id: user?.id } })
					toast.success(`${user?.name}'s password has been reset to PhoxHealth@2023.`)
					showSpinner(false)
				} catch (error) {
					showSpinner(false)
					toast.error("An error occurred while resetting the user's password.")
					console.error("reset password error:", error)
				}

			}
		});
	}


	const menus = [
		{ 'name': 'Active', 'url': `/user`, 'active': true },
		{ 'name': 'Invites', 'url': `/user/invite`, 'active': false }
	]

	return (
		<>
			<PageHeader name='Users' menus={menus} shipperLabel={shipperGroup}>
				{isOwner(myUserRole) && < Link className='btn btn-dark' to={`/user/invite/create`}>Invite User</Link>}
				<Button className='btn btn-light ms-2' onClick={() => { searchUser() }}><i className='fe fe-refresh-cw'></i></Button>
			</PageHeader >
			<Container fluid>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
							<ElasticFacetFilter name='Client' item={shipperGroup} facets={facets} facetName='shipperGroups' onChange={setShipperGroup} />
							<ElasticFacetFilter name='Location' item={shipper} facets={facets} facetName='shipper' onChange={setShipper} />
							<ElasticFacetFilter name='Courier' item={carrier} facets={facets} facetName='carrier' onChange={setCarrier} />
							<ElasticFacetFilter name='Role' item={role} facets={facets} facetName='role' onChange={setRole} />
							<PatientActiveFilter active={active} onChange={setActive} />
							<TableClearFilter onClick={clearFilters} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm'>
							<thead>
								<tr>
									<th className='text-center'>Status</th>
									<th>Name / Email</th>
									<th className='text-center'>Login Provider</th>
									<th className='text-center'>Last Login</th>

									<th className='text-center'>Admin</th>
									<th className='text-center'>Shipper Group</th>
									<th className='text-center'>Shipper</th>
									<th className='text-center'>Courier</th>

									<th className='text-center'>Action</th>
								</tr>
							</thead>
							<tbody>
								{
									users && users.map((user, index) => {
										return (
											<tr key={index}>
												<td className='text-center'>
													{
														user?._source?.active ?
															<span className={`badge bg-success`}>Active</span>
															: <span className={`badge bg-danger-soft`}>Disabled</span>
													}
												</td>
												<td>
													{user?._source?.name}
													<div className='text-muted'>{user?._source?.email}</div>
												</td>
												<td className='text-center'>
													{user?._source?.loginProvider}
												</td>
												<td className='text-center'>
													<div>{toLocalTime(user?._source?.loginAt)}</div>
													<div className='small text-muted'>{toLocalDate(user?._source?.loginAt)}</div>

												</td>

												<td className='text-center'>
													<UserRoleBadge role={toTitleCase(user?._source?.role)} />
												</td>
												<td className='text-center'>{user?._source?.shipperGroups?.length || '-'}</td>
												<td className='text-center'>{user?._source?.shippers?.length || '-'}</td>
												<td className='text-center'>{user?._source?.carriers?.length || '-'}</td>

												<td className='text-end'>
													<div className='dropdown'>
														<Link to='' className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
															<i className="fe fe-more-vertical" />
														</Link>
														<div className='dropdown-menu dropdown-menu-end'>
															<button className='dropdown-item' onClick={() => isOwner(myUserRole) && navigate(`/user/${user._source.id}/overview`)}>View</button>
															<button className="dropdown-item" onClick={() => resetPassword(user?._source)}>
																Reset Password
															</button>
														</div>
													</div>
												</td>
											</tr>
										)
									})
								}
							</tbody>
							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={8} />
						</Table>
					</Spinner>
				</Card>
			</Container>
		</>
	)
}

export default UserList;
