import moment from 'moment';
import React from 'react';
import { Button, Col, Dropdown } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Link } from 'react-router-dom';
import awsConfig from "../aws-exports";
import boxStatus from "../data/box-status.json";
import { PAGE_SIZE } from '../helpers';
import ShipmentStatus from './../data/shipment-status.json';

export const TableKeywordSearch = ({ keyword, onChange, placeholder }) => {
    return (
        <>
            <div className='col' style={{ minWidth: '250px' }}>
                <div className='input-group input-group-sm input-group-flush input-group-merge input-group-reverse'>
                    <input className='form-control list-search' type='text' placeholder={placeholder || 'Search'} value={keyword || ''} onChange={({ target: { value } }) => { onChange(value) }} />
                    <span className='input-group-text'>
                        <i className='fe fe-search'></i>
                    </span>
                </div>
            </div>
        </>
    )
}

export const TableHomeButton = ({ dark }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Link to='/dashboard' className={`btn btn-sm ${color}`}>
                <i className='fe fe-home' />
            </Link>
        </Col>
    )
}

export const TableFacetFilter = ({ name, item, facets, facetName, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {name}: {item || 'All'}
                </button>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    {
                        facets && facets[facetName] && Object.keys(facets[facetName])?.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'accent' })).map((item, index) => {
                            return <button key={index} className='dropdown-item' onClick={() => onChange(item)} >
                                {item} ({facets[facetName][item]})
                            </button>
                        })
                    }
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}

export const TableStatusFilter = ({ name, item, facets, facetName, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {name}: {ShipmentStatus[item] || 'All'}
                </button>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    {
                        facets && facets[facetName] && Object.keys(facets[facetName])?.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'accent' }))?.map((item, index) => {
                            return <button key={index} className='dropdown-item' onClick={() => onChange(item)} >
                                {ShipmentStatus[item]} ({facets[facetName][item]})
                            </button>
                        })
                    }
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}

export const TableGroupFilter = ({ name, item, list, dark, onChange, openLeft = false, }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {name}: {item?._source?.name || 'All'}
                </button>
                <div className={`dropdown-menu  ${openLeft && 'dropdown-menu-right'}`} aria-labelledby='dropdownMenuButton'>
                    <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
                        {
                            list.map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => onChange(item)} >
                                    {item?._source?.name}
                                </button>
                            })
                        }
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}

export const TableShipperFilter = ({ name, item, list, dark, onChange, openLeft = false }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {name}: {item?._source?.name || 'All'}
                </button>
                <div className={`dropdown-menu  ${openLeft && 'dropdown-menu-right'}`} aria-labelledby='dropdownMenuButton'  >
                    <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
                        {
                            list?.map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => onChange(item)} >
                                    {item?._source?.name}
                                </button>
                            })
                        }
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}

export const BoxStatusFilter = ({ value, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'

    const statusOptions = [
        { label: 'Available', value: 'AVAILABLE' },
        { label: 'In Use', value: 'IN_USE' },
        { label: 'Out for Return', value: 'OUT_FOR_RETURN' },
        { label: 'Returned', value: 'RETURNED' },
        { label: 'Damaged', value: 'DAMAGED' },
        { label: 'Retired', value: 'RETIRED' },
    ]

    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Status: {boxStatus[value] || 'All'}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
                        {
                            Object.keys(statusOptions).map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => onChange(statusOptions[item].value)} >
                                    {statusOptions[item].label}
                                </button>
                            })
                        }
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}

export const WarehouseFilter = ({ warehouseList, dark, selectedWarehoue, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Warehouse: {selectedWarehoue?.name || 'All'}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
                        {
                            warehouseList.map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => { onChange(item) }} >
                                    {item?.name}
                                </button>
                            })
                        }
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange({})}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}

export const TimeZoneFilter = ({ title, value, dark, onChange, setTz, tz }) => {
    const color = 'btn-light'
    const textColor = !dark ? 'text-light' : 'text-dark'
    const statusOptions = [
        { label: 'EST', value: 'America/New_York' },
        { label: 'CST', value: 'America/Chicago' },
        { label: 'MST', value: 'America/Denver' },
        { label: 'PST', value: 'America/Los_Angeles' },
    ]


    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>{title}</div>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {tz || 'All'}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div>
                        {
                            Object.keys(statusOptions).map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => { onChange(statusOptions[item].value); setTz(statusOptions[item].label) }} >
                                    {statusOptions[item].label}
                                </button>
                            })
                        }
                    </div>
                    {/* <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button> */}
                </div>
            </Dropdown>
        </Col>
    )
}



export const TableDateFilter = ({ dark, onChange, startOf = 0 }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    const handleDateChange = (event, picker) => {
        const fromDate = moment.tz(picker.startDate.format('YYYY-MM-DD 00:00:00'), 'America/New_York').unix()
        const toDate = moment.tz(picker.endDate.format('YYYY-MM-DD 23:59:59'), 'America/Los_Angeles').unix()
        onChange({ fromDate, toDate })
    }

    return (
        <Col className='col-auto px-1 my-1'>
            <DateRangePicker onApply={handleDateChange}
                initialSettings={{
                    startDate: moment().subtract(startOf, 'days'),
                    endDate: moment(),
                    showDropdowns: true,
                    alwaysShowCalendars: true,
                    opens: 'left',
                    locale: { format: 'MMM D, YYYY' },
                    ranges: {
                        'Tomorrow': [moment().add(1, 'day'), moment().add(1, 'day')],
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    }
                }}>
                <input className={`btn btn-sm ${color}`} />
            </DateRangePicker>
        </Col>
    )
}

export const TableClearFilter = ({ dark, onClick, styleName }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className={`col-auto px-1 my-1 ${styleName}`}>
            <Button className={`btn ${color} btn-sm pb-0`} onClick={onClick} title='Clear Filter'>
                <i className='fe fe-x-circle'></i>
            </Button>
        </Col>
    )
}

export const PatientActiveFilter = ({ active, onChange }) => {
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='bg-light rounded'>
                <button className={`btn btn-sm  dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Status:  {active === '' ? 'All' : active === true ? 'Active' : "Inactive"}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
                        <Dropdown.Item onClick={() => onChange(true)}>Active</Dropdown.Item>
                        <Dropdown.Item onClick={() => onChange(false)}>Inactive</Dropdown.Item>
                        <hr />
                        <Dropdown.Item onClick={() => onChange('')}>All</Dropdown.Item>
                    </div>
                </div>
            </Dropdown>
        </Col>
    )
}

export const TableExportFilter = ({ option, onChange }) => {
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown>
                <Dropdown.Toggle variant='light' size='sm'>Export</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onChange}>{option}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    )
}
export const MapToggleButton = ({ setMapStyle, mapStyle }) => {

    let awsExportMapStryle = Object.keys(awsConfig.geo.amazon_location_service.maps.items)
    const defaultMapStyle = awsExportMapStryle.filter(element => !element.includes('satellite'));
    const satelliteMapStyle = awsExportMapStryle.filter(element => element.includes('satellite'));

    return (
        <div className="btn-group btn-group me-2 mb-3" role="group" aria-label="First group">
            <Button className={`btn btn-${mapStyle === defaultMapStyle.join() ? "dark text-white" : "white"} m-0`} onClick={() => setMapStyle(defaultMapStyle.join())}  >
                <span className="fe fe-map-pin"></span>

            </Button>

            <Button className={`btn btn-${mapStyle === satelliteMapStyle.join() ? "dark text-white" : "white"} m-0`} onClick={() => setMapStyle(satelliteMapStyle.join())} >
                <span className="fe fe-map"></span>
            </Button>
        </div>

    )
}

export const TablePagination = ({ pageNumber, pageCount, setPageNumber, colSpan }) => {
    return (
        <tfoot>
            <tr>
                <td colSpan={colSpan}>
                    {
                        pageCount > 0 ? <ul className='pagination pagination-sm mb-0 justify-content-center'>
                            {
                                pageNumber !== 0 &&
                                <li className='page-item'>
                                    <button className='page-link bg-light' onClick={() => { setPageNumber(pageNumber - 1) }}>
                                        Previous
                                    </button>
                                </li>
                            }

                            <li className='page-item'>
                                <button className='page-link'>Page {pageNumber + 1} of {pageCount}</button>
                            </li>

                            {
                                (pageCount - 1) > pageNumber &&
                                <li className='page-item'>
                                    <button className='page-link bg-light' onClick={() => { setPageNumber(pageNumber + 1); }}>
                                        Next
                                    </button>
                                </li>
                            }
                        </ul> : <div className='text-center'>No records found</div>
                    }
                </td>
            </tr>
        </tfoot>
    )
}


export default function Pagination({ handleNext, handlePrev, length, token, total, colSpan }) {
    return (
        <>
            {
                total > PAGE_SIZE &&
                <tfoot>
                    <tr>
                        <td colSpan={colSpan}>

                            <ul className='pagination pagination-sm mb-0 justify-content-center'>
                                {length > 1 && (
                                    <button className="page-link bg-light" onClick={handlePrev}>
                                        <i className="fe fe-arrow-left ms-1"></i>
                                    </button>
                                )}
                                {/* <li className='page-item'>
                            <button className='page-link'>Page {pageNumber + 1} of {pageCount}</button>
                        </li> */}

                                {total >= PAGE_SIZE && (
                                    <>
                                        {
                                            total < 0 && <div className='text-center'>No records found</div>
                                        }
                                        <button className="page-link bg-light" onClick={handleNext}>
                                            <i className="fe fe-arrow-right ms-1"></i>
                                        </button>
                                    </>
                                )}
                            </ul>


                        </td>
                    </tr>
                </tfoot >
            }
        </>
    );
}


export const UserCarrierFilter = ({ value, onChange, carrierList, dark }) => {
    const name = value?.name ?? 'All';
    const color = dark ? 'btn-dark' : 'btn-light'

    return (

        <Col className='col-auto px-1 my-1 col'>
            <Dropdown>
                <Dropdown.Toggle size='sm' variant='light' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Courier: {name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        carrierList.map((item, index) =>
                            <Dropdown.Item key={index} className='px-3' onClick={() => onChange(item)}>
                                {item?.name}
                            </Dropdown.Item>
                        )
                    }
                    <hr className='my-1' />
                    <Dropdown.Item className='px-3' onClick={() => onChange()}>
                        All
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    )
}