/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, Form, FormGroup, Modal, Row, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { StatCard } from '../../components/Card';
import { CarrierFilter, CarrierFilterFromDeliveryService, DateFilter, DeliveryServiceFilter, GreenPhoxFilter, ListPageDriverFilter, ListWarehouseFilter, ShipperFilter, ShipperGroupFilter, StatusFilter } from '../../components/EntityFilter';
import FormLabel from '../../components/FormLabel';
import AsyncSelect from 'react-select/async';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { StatusBadge } from '../../components/StatusBadge';
import { TableExportFilter, TableKeywordSearch, TablePagination, TimeZoneFilter } from '../../components/TableFilter';
import BoxStatus from "../../data/box-status.json";
import { DELAY_TIMEOUT, getTZ, handleApiError, PAGE_SIZE, toLocalDate, toLocalTime } from '../../helpers';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from "react-ga4"
import { TableClearFilter } from '../../components/TableFilter';




const exportShipmentData = /* GraphQL */ `
      mutation ExportShipmentData($input: AWSJSON!) {
        exportShipmentData(input: $input)
      }
    `;

const updateShipmentBoxMutation = /* GraphQL */ `
	mutation UpdateShipmentBox(
	  $input: UpdateShipmentBoxInput!
	) {
	  updateShipmentBox(input: $input) {
		id
	  }
	}
  `;

const createBoxStatusHistory = /* GraphQL */ `
  mutation CreateBoxStatusHistory(
	$input: CreateBoxStatusHistoryInput!
	$condition: ModelBoxStatusHistoryConditionInput
  ) {
	createBoxStatusHistory(input: $input, condition: $condition) {
	  id
	  boxId
	  userId
	  shipmentId
	}
  }
  `;


const TaskList = ({ dark }) => {

	const myUser = useSelector(state => state.slice.USER)
	const navigate = useNavigate()
	const [userList, setUserList] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [spinner, showSpinner] = useState(true);
	const [shipments, setShipments] = useState([])
	const [facets, setFacets] = useState({})
	const [tz, setTz] = useState("EST");
	const [keyword, setKeyword] = useState('')
	const [shipperGroup, setShipperGroup] = useState()
	const [shipper, setShipper] = useState()
	const [carrier, setCarrier] = useState()
	const [status, setStatus] = useState();
	const [deliveryServices, setDeliveryServices] = useState();
	const [deliveryService, setDeliveryService] = useState();
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [isGreenPhox, setIsGreenPhox] = useState();
	const [boxModal, showBoxModal] = useState(false);
	const [boxModalShipment, setBoxModalShipment] = useState({});
	const [boxStatusHistoryData, setBoxStatusHistoryData] = useState({});
	const [statusDate, setStatusDate] = useState(new Date());
	const [driver, setDriver] = useState();
	const [selectedWarehouse, setSelectedWarehouse] = useState();
	const [taskList, setTaskList] = useState({});
	const [rawTaskList, setRawTaskList] = useState([])
	const [loading, setLoading] = useState(true)
	const [timeZone, setTimeZone] = useState("America/New_York");
	const [resetDate, setRestDate] = useState(0);
	const params = useParams();
	const textColor = dark ? 'text-light' : 'text-dark'
	const allUsers = [];
	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz(timeZone)?.startOf('day').unix(),
		toDate: moment().tz(timeZone)?.endOf('day').unix()
	});


	async function getTaskList() {
		showSpinner(true)
		try {
			const apiName = 'api';
			const path = `/search/task?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					sort: [
						{
							"_score": { "order": "desc" }
						},
						{
							"createdTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					query: {
						bool: {
							must: []
						}
					}
				}
			};

			if (!keyword) {
				init.body.query.bool.must.push({
					range: {
						"createdTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (selectedUser) {
				init.body.query.bool.must.push({ match: { "userId.keyword": selectedUser?.value } })
			}
			if (keyword) {
				let fields = [
					"label",
					"description",
					'title'
				]
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			const res = await API.post(apiName, path, init);
			const items = res.hits.hits.map(item => item._source);
			setRawTaskList(items);

			const groupedTasks = Object.groupBy(items, ({ label }) => label);
			setTaskList(groupedTasks);

			const uniqueUsers = [...new Map(items.map(task => [task.user?.name, task.user])).values()].filter(Boolean);
			setUserList(uniqueUsers);
			setPageCount(Math.ceil(res.hits.total.value / PAGE_SIZE));
			setLoading(false);
			showSpinner(false);


		} catch (error) {
			showSpinner(false);
			handleApiError(error)
		}

	}

	const searchUser = async (keyword) => {
		let items = [];
		try {
			const apiName = 'api';
			const path = `/search/user?size=100&from=0`;
			let init = {
				body: {
					sort: [
						{
							"_score": { "order": "desc" }
						}
					],
					query: {
						bool: {
							must: [
								{ match: { active: true } },
								{
									terms: { "role.keyword": ['OWNER', 'EDITOR', 'VIEWER'] }
								}
							]
						}
					}
				}
			};


			if (keyword) {
				delete init.body.sort;
				init.body.query.bool.must.push({
					"multi_match": {
						"query": keyword,
						"fields": ["name"],
						"fuzziness": "AUTO",
						"minimum_should_match": "1<2",
						"type": "best_fields"
					}
				});
			}
			let data = await API.post(apiName, path, init);
			data.hits.hits.forEach(item => items.push({
				label: item?._source?.name,
				value: item?._source?.id
			}));
			return items
		} catch (error) {
			console.error('error from  elastic search cutomer', error)
		}
	}



	const DEBOUNCE_DELAY = 200;
	useEffect(() => {
		const handler = setTimeout(() => {
			getTaskList();
		}, DEBOUNCE_DELAY);
		return () => {
			clearTimeout(handler);
		};
	}, [keyword, dateFilters, pageNumber, selectedUser]);
	useEffect(() => {
		setPageNumber(0)
	}, [keyword]);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/task",
		})
	}, [])

	useEffect(() => {
		const getTimeZone = async () => {
			const timez = await getTZ(shipperGroup?.value, shipper?.value, carrier?.value);
			if (timez) {
				setTimeZone(timez?.id);
				setTz(timez?.alias)
			}
			else {
				setTimeZone("America/New_York")
				setTz("EST")
			}
		};

		if (shipperGroup?.value || shipper?.value || carrier?.value) getTimeZone();
	}, [shipperGroup?.value, shipper?.value, carrier?.value]);


	useEffect(() => {
		setPageNumber(0);
		const delay = setTimeout(() => { setPageNumber(0) }, DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword, dateFilters, selectedUser])

	useEffect(() => {
		getTaskList()
	}, [pageNumber, dateFilters]);


	const clearFilters = () => {
		setDateFilters(null);
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
		setUserList(allUsers);
		setLoading(true)
		getTaskList();
		setSelectedUser(null);
		setPageNumber(0);

	}

	const totalOpenTasks = rawTaskList?.filter(task => task.status === 'OPEN').length || 0;
	const totalCloseTasks = rawTaskList?.filter(task => task.status === 'DONE').length || 0;
	const totalUsers = new Set(rawTaskList?.map(task => task?.user?.name).filter(name => name !== undefined)).size;



	return (

		<>
			<PageHeader name='Task List' >
				<div className="d-flex align-items-center gap-3 px-1 my-1">
					<Row>
						<Col className='mt-1'>
							<div className={`small ${textColor}`}>Users</div>
							<AsyncSelect cacheOptions defaultOptions
								styles={{
									control: (baseStyles) => ({
										...baseStyles,
										fontSize: '0.75rem',
										backgroundColor: '#EDF2F9',
										borderWidth: '0px',
										width: '200px',
										maxBlockSize: '10px',
										minHeight: '30px'
									}),
									dropdownIndicator: (baseStyles) => ({
										...baseStyles,
										padding: '0 4px',
									}),
								}}

								placeholder='Select User'
								value={selectedUser ? { value: selectedUser?.value, label: selectedUser?.label } : null}
								loadOptions={searchUser}
								onChange={(e) => setSelectedUser(e)}
							/>
						</Col>
						<Col>
							<DateFilter key={resetDate} onChange={setDateFilters} />
						</Col>
						<TableClearFilter onClick={clearFilters} styleName="mt-4" />
					</Row>
				</div>
			</PageHeader>

			<Container fluid>
				<Row>
					<StatCard title='OPEN' value={totalOpenTasks} icon='clock' />
					<StatCard title='DONE' value={totalCloseTasks} icon='check-circle' />
					<StatCard title='USERS' value={totalUsers} icon='users' />
				</Row>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm' className='mb-0' >
							<thead>
								<tr>
									<th className='text-center'>Label</th>
									<th className='text-center'>Date</th>
									<th className='text-center'>Title</th>
									<th className='text-center'>Shipment No</th>
									<th className='text-center'>User</th>
									<th className=''>Status</th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(taskList).flatMap(label =>
									taskList[label].map(task => (
										<tr key={task.id}>
											<td className='text-center'><div>{task.label || '-'}</div></td>
											<td className='text-center'><div>{new Date(task.createdAt).toLocaleDateString() || '-'}</div></td>
											<td className='text-center'><div>{task.title || '-'}</div></td>
											<td className='text-center'><div>{task?.shipment?.number || '-'}</div></td>
											<td className='text-center'><div>{task?.user?.name || '-'}</div></td>
											<td className='text-center'
											><div style={{
												backgroundColor: task.status === 'OPEN' ? '#edf2f9' : task.status === 'DONE' ? ' #00d97e' : 'transparent',
												color: task.status === 'OPEN' ? '#283e59' : task.status === 'DONE' ? '#fff' : '#000',
												borderRadius: '0.375rem',
												padding: '0.33em 0.5em',
												fontSize: '76%',
												fontWeight: 400,
												maxWidth: '50px',
												alignContent: 'end'
											}}>{task?.status || '-'}</div></td>
										</tr>
									))
								)}
							</tbody>
							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={8} />
						</Table>
					</Spinner>
				</Card>
			</Container >
		</>
	);
};

export default TaskList;
