/* eslint-disable react-hooks/exhaustive-deps */

import { Amplify } from 'aws-amplify';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import AdminLayout from './components/AdminLayout';
import AuthLayout from './components/AuthLayout';
import MarkdownPage from './components/MarkDownPage';
import Host from './data/host.json';
import PageNotFound from './pages/PageNotFound';
import Unauthorized from './pages/Unauthorized';
import DownloadApp from './pages/auth/DownloadApp';
import Invite from './pages/auth/Invite';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import BillingReport from './pages/billingReport/BillingReport';
import CarrierEditor from './pages/carrier/CarrierEditor';
import CarrierList from './pages/carrier/CarrierList';
import CarrierOverview from './pages/carrier/CarrierOverview';
import CarrierUsers from './pages/carrier/CarrierUser';
import Dashboard from './pages/dashboard/Dashboard';
import Dispatch from './pages/dashboard/Dispatch';
import DriverTracker from './pages/dashboard/DriverTracker';
import LiveTracker from './pages/dashboard/LiveTracker';
import Rating from './pages/dashboard/Rating';
import AdminBoxes from './pages/greenphox/AdminBoxes';
import BoxDetails from './pages/greenphox/BoxDetails';
import PatientEditor from './pages/patients/PatientEditor';
import PatientsList from './pages/patients/PatientsList';
import Profile from './pages/profile/Profile';
import ShipmentDetail from './pages/shipment/ShipmentDetail';
import ShipmentList from './pages/shipment/ShipmentList';
import ShipperEditor from './pages/shipper/ShipperEditor';
import ShipperList from './pages/shipper/ShipperList';
import ShipperOverview from './pages/shipper/ShipperOverview';
import ProductsList from './pages/shipper/ShipperProduct';
import ShipperProductEditor from './pages/shipper/ShipperProductEditor';
import ShipperService from './pages/shipper/ShipperService';
import ShipperServiceEditor from './pages/shipper/ShipperServiceEditor';
import ShipperSettings from './pages/shipper/ShipperSettings';
import ShipperUsers from './pages/shipper/ShipperUser';
import ShipperGroupEditor from './pages/shipperGroup/ShipperGroupEditor';
import ShipperGroupList from './pages/shipperGroup/ShipperGroupList';
import ShipperGroupOverview from './pages/shipperGroup/ShipperGroupOverview';
import ShipperGroupUser from './pages/shipperGroup/ShipperGroupUser';
import Task from './pages/task/Task';
import InviteCreate from './pages/user/InviteCreate';
import InviteList from './pages/user/InviteList';
import UserList from './pages/user/UserList';
import UserOverview from './pages/user/UserOverview';

import '@aws-amplify/ui-react-geo/styles.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/light.css';
import 'react-phone-input-2/lib/style.css';

import BillingTransactions from './pages/billingReport/BillingTransactions';
import BillingInvoices from './pages/billingReport/BillingInvoices';
import Bills from './pages/billingReport/Bills';
import BillingSettings from './pages/billingReport/BillingSettings';
import Manage from './pages/greenphox/Manage';
import PhoxTail from './pages/phoxtail/PhoxTail';


import awsExport from './aws-exports';
import TaskList from './pages/task/TaskList';
import CommunicationLog from './pages/communicationLog/CommunicationLog';
import Today from './pages/dashboard/Today';

let awsExportConfig = awsExport;
if (Object.keys(Host).includes(window.location.host)) {
	awsExportConfig.oauth = Host[window.location.host].oauth;
}
Amplify.configure(awsExportConfig);


function App() {

	ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

	return (
		<>
			<Routes>
				<Route element={<AuthLayout />} >
					<Route exact path='/' element={<Login />} />
					<Route exact path='/invite/:id' element={<Invite />} />
					<Route exact path='/auth/logout' element={<Logout />} />
					<Route exact path='/trax/download' element={<DownloadApp />} />
				</Route>

				<Route exact path='/map' element={<LiveTracker />} />
				<Route exact path='/dispatch' element={<Dispatch />} />
				<Route exact path='/map/driver/:id' element={<DriverTracker />} />

				<Route element={<AdminLayout />} >
					<Route exact path='/changelog' element={<MarkdownPage />} />

					{/* dashboards   */}
					<Route exact path='/today' element={<Today />} />
					<Route exact path='/dashboard' element={<Dashboard />} />
					<Route exact path='/star-phox' element={<Rating />} />
					<Route exact path='/task' element={<Task />} />
					<Route exact path='/tasklist' element={<TaskList />} />
					<Route exact path='/task/:id' element={<Task />} />

					{/* Shipper Groups */}
					<Route exact path='/shipper-group' element={<ShipperGroupList />} />
					<Route exact path='/shipper-group/add' element={<ShipperGroupEditor />} />
					<Route exact path='/shipper-group/:shipperGroupId/edit' element={<ShipperGroupEditor />} />
					<Route exact path='/shipper-group/:shipperGroupId/overview' element={<ShipperGroupOverview />} />
					<Route exact path='/shipper-group/:shipperGroupId/user' element={<ShipperGroupUser />} />

					{/* Shippers  */}
					<Route exact path='/shipper-group/:shipperGroupId/shipper' element={<ShipperList />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/add' element={<ShipperEditor />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/edit' element={<ShipperEditor />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/overview' element={<ShipperOverview />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/user' element={<ShipperUsers />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/setting' element={<ShipperSettings />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/service' element={<ShipperService />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/service/add' element={<ShipperServiceEditor />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/service/:serviceId/edit' element={<ShipperServiceEditor />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/product' element={<ProductsList />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/product/add' element={<ShipperProductEditor />} />
					<Route exact path='/shipper-group/:shipperGroupId/shipper/:shipperId/product/:productId/edit' element={<ShipperProductEditor />} />


					{/* Shipments  */}
					<Route exact path='/shipment' element={<ShipmentList />} />
					<Route exact path='/shipment/:id' element={<ShipmentDetail />} />

					{/* Green Phox  */}
					<Route exact path='/green-phox' element={<AdminBoxes />} />
					<Route exact path='/green-phox/manage' element={<Manage />} />
					<Route exact path='/box/details/:shipmentid' element={<BoxDetails />} />


					{/* Carriers  */}
					<Route exact path='/courier' element={<CarrierList />} />
					<Route exact path='/courier/add' element={<CarrierEditor />} />
					<Route exact path='/courier/:id/edit' element={<CarrierEditor />} />
					<Route exact path='/courier/:id/overview' element={<CarrierOverview />} />
					<Route exact path='/courier/:id/user' element={<CarrierUsers />} />

					{/* Users */}
					<Route exact path='/user' element={<UserList />} />
					<Route exact path='/user/:userId/overview' element={<UserOverview />} />

					{/* patients  */}
					<Route exact path='/patients' element={<PatientsList />} />
					<Route exact path='/patient/create' element={<PatientEditor />} />
					<Route exact path='/patient/edit/:id' element={<PatientEditor />} />

					<Route exact path='/user/invite' element={<InviteList />} />
					<Route exact path='/user/invite/create' element={<InviteCreate />} />
					<Route exact path='/billing' element={<BillingReport />} />
					<Route exact path='/communication-log' element={<CommunicationLog />} />

					{/* profile  */}
					<Route exact path='/profile' element={<Profile />} />
				</Route>
				<Route path='*' element={<PageNotFound />} />
				<Route path='/unauthorized' element={<Unauthorized />} />
			</Routes>
			<Toaster />
		</>
	);
}

export default App;