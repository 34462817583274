/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Card, Container, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { PAGE_SIZE, handleApiError, isViewer } from '../../helpers';
import ReactGA from "react-ga4"


export const productsByShipperId = /* GraphQL */ `
  query ProductsByShipperId(
    $shipperId: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
  ) {
    productsByShipperId(
      shipperId: $shipperId
      sortDirection: $sortDirection
      limit: $limit
    ) {
      items {
        id
        shipperGroupId
        shipperId
        name
      }
    }
  }
`;


export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
    name
     }
  }
`;

const ProductsList = () => {

    const { shipperGroupId, shipperId } = useParams();
    const myShipper = useSelector((state) => state.slice.SHIPPER)
    const myUserRole = useSelector((state) => state.slice.USER.role)
    const navigate = useNavigate()

    const [products, setProducts] = useState([]);
    const [spinner, showSpinner] = useState(true);

    const menus = [
        { 'name': 'Overview', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/overview`, 'active': false },
        { 'name': 'Settings', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/setting`, 'active': false },
        { 'name': 'Services', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/service`, 'active': false },
        { 'name': 'Products', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/product`, 'active': true },
    ]

    useEffect(() => {
        getShipperProducts()
        ReactGA.send({
            hitType: "pageview",
            page: `/shipper-group/${shipperGroupId}/shipper/${shipperId}/product`,
        })
    }, [])

    const getShipperProducts = () => {
        API.graphql({ query: productsByShipperId, variables: { shipperId: shipperId }, limit: PAGE_SIZE })
            .then(({ data }) => setProducts(data?.productsByShipperId?.items))
            .catch((err) => handleApiError(err))
            .finally(() => showSpinner(false))
    }

    const removeProduct = (id) => {
        swal({
            text: 'Are you sure that you want to remove this Product?',
            buttons: ['Cancel', 'Remove'],
            dangerMode: true,
        }).then(status => {
            if (status) {
                showSpinner(true)
                API.graphql({ query: deleteProduct, variables: { input: { id: id } } })
                    .then((data) => { toast.success('Product has been removed'); getShipperProducts() })
            }
        })
    }

    return (
        <>
            <PageHeader title={myShipper?.name} name='Products' menus={menus} image={myShipper?.image}>
                {!isViewer(myUserRole) && <Link className='btn btn-dark' to={`/shipper-group/${shipperGroupId}/shipper/${shipperId}/product/add`}>  Add Product </Link>}
            </PageHeader>
            <Container fluid>
                <Card>
                    <Spinner
                        display={spinner}
                    >
                        <Table className='table table-hover table-sm mb-0'>
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    products?.map((product) => {
                                        return (
                                            <tr key={product?.id} className={'cursor-pointer'}>
                                                <td>{product?.name} {product.featured === true && <span className='fe fe-star'></span>}</td>
                                                {!isViewer(myUserRole) &&
                                                    < td className='text-center'>
                                                        <button className='btn btn-sm bg-light' onClick={() => { navigate(`/shipper-group/${shipperGroupId}/shipper/${shipperId}/product/${product?.id}/edit`) }}  >
                                                            <i className='fe fe-edit text-denger'></i>
                                                        </button>
                                                        <button className='btn btn-sm bg-light ms-2' onClick={() => removeProduct(product?.id)}  >
                                                            <i className='fe fe-trash text-denger'></i>
                                                        </button>
                                                    </td>}
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Spinner>
                </Card>
            </Container >
        </>
    )
}

export default ProductsList
