/* eslint-disable react-hooks/exhaustive-deps */
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { storeShipperGroup, storeShippers } from '../../stores/slice';
import { useDispatch } from 'react-redux';

const Logout = () => {
	const dispatch = useDispatch()

	const navigate = useNavigate();
	useEffect(() => logout(), [])
	
	function logout() {
		localStorage.removeItem('phap')
		Auth.signOut().then((res) => navigate('/'));
		dispatch(storeShipperGroup({}));
		dispatch(storeShippers({}));
	}
}

export default Logout;
