/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import ReactGA from "react-ga4";
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import swal from 'sweetalert';
import uniqid from 'uniqid';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import DeliveryService from '../../data/delivery-service.json';
import DeliveryTat from '../../data/delivery-tat.json';
import FedExService from '../../data/fedex-service.json';
import UpsService from '../../data/ups-service.json';
import UspsService from '../../data/usps-service.json';
import { FormError, handleApiError } from '../../helpers';

const getDeliveryServiceByIdQuery = /* GraphQL */ `
  query GetDeliveryServiceById($id: ID!) {
    getDeliveryService(id: $id) {
      id
      type
      name
      value
      pickupBy
      orderBy
      tatMin
      tatMax
      maxDistance
      sort
      active
      slackChannel
      postalcode_whitelist
      postalcode_blacklist
      carrierId 
      carrier {
        name
      }
      shipments {
        items {
            id
        }
      }
    }
  }
`;

const createDeliveryService = /* GraphQL */ `
  mutation CreateDeliveryService($input: CreateDeliveryServiceInput!) {
    createDeliveryService(input: $input) {
        id
    }
  }
`;

const updateDeliveryService = /* GraphQL */ `
  mutation UpdateDeliveryService($input: UpdateDeliveryServiceInput!) {
    updateDeliveryService(input: $input) {
      id
    }
  }
`;

const deleteDeliveryService = /* GraphQL */ `
  mutation UpdateDeliveryService($input: DeleteDeliveryServiceInput!) {
    deleteDeliveryService(input: $input) {
      id
    }
  }
`;

const listCarriers = /* GraphQL */ `
  query ListCarriers(
    $filter: ModelCarrierFilterInput
    $limit: Int
  ) {
    listCarriers(filter: $filter, limit: $limit) {
      items {
        id
        name
        alias
      }
    }
  }
`;

const DeliveryServiceEditor = () => {

    const navigate = useNavigate();
    const myShipper = useSelector((state) => state.slice.SHIPPER);
    const { shipperGroupId, shipperId, serviceId } = useParams();

    const [spinner, showSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [carrier, setCarrier] = useState();
    const [carriers, setCarriers] = useState([]);
    const [service, setService] = useState({ active: true, pickupBy: moment().format("HH:mm"), orderBy: moment().format("HH:mm") });
    let otherCarrierList = ['fedex', 'usps', 'ups']

    useEffect(() => {
        getCarriers();
        if (serviceId) getServiceById();

        ReactGA.send({
            hitType: "pageview",
            page: `/shipper-group/${shipperGroupId}/shipper/${shipperId}/service/add`,
        })
    }, [])

    useEffect(() => {
        if (service?.carrierId) {
            const serviceCarrier = carriers.find(x => x.id === service.carrierId)
            if (serviceCarrier) setCarrier(serviceCarrier)
        }
    }, [carriers, service])

    const getCarriers = async () => {
        showSpinner(true)
        try {

            API.graphql({ query: listCarriers, variables: { limit: 1000 } }).then((response) => {
                let carriers = response.data.listCarriers.items.sort((a, b) => (a?.name.localeCompare(b?.name, undefined, { sensitivity: 'accent' })))
                setCarriers(carriers)
            })

            showSpinner(false)
        } catch (error) {
            console.error(error);
            toast.error(error);
            showSpinner(false)
        }
    }

    const getServiceById = () => {
        showSpinner(true)
        API.graphql({ query: getDeliveryServiceByIdQuery, variables: { id: serviceId } })
            .then(({ data }) => setService(data?.getDeliveryService))
            .catch((error) => handleApiError(error))
            .finally(() => showSpinner(false))
    };

    const isFormValid = () => {
        let error = ((Object.keys(errors).length > 0) && errors?.blacklist && errors?.whitelist) ? errors : {};

        if (!service.carrierId) error.carrierId = 'Carrier is required'
        if (['fedex', 'usps', 'ups'].includes(carrier?.alias?.toLowerCase())) {
            if (!service?.value || service?.value === '-') error.value = "Service type is required"
        }

        if (!['fedex', 'usps', 'ups'].includes(carrier?.alias?.toLowerCase())) {
            if (!service.type || service?.type === '-') error.type = 'Service Type is required'
            if (!service.name) error.name = 'Service Name is required'
            if (!service.pickupBy || service.pickupBy === '-') error.pickupBy = 'Pickup By is required'
            if ((!service.orderBy && !service.pickupBy) || service.orderBy === '-') error.orderBy = 'Order By is required'
            if (!service.tatMin || service.tatMin === '-') error.tatMin = 'Minimum Turnaround Time is required'
            if (!service.tatMax || service.tatMax === '-') error.tatMax = 'Maximum Turnaround Time is required'
            if (service?.maxDistance && service?.maxDistance <= 0) error.maxDistance = 'The Maximum Delivery Distance must be more than 0'

            if (service?.postalcode_whitelist?.length > 0) {
                service?.postalcode_whitelist?.forEach((zip) => {
                    if (service?.postalcode_blacklist?.includes(zip)) error.whitelist = "Zipcode included in Blacklist."
                })
            }
            if (service?.postalcode_blacklist?.length > 0) {
                service?.postalcode_blacklist?.forEach((zip) => {
                    if (service?.postalcode_whitelist?.includes(zip)) error.blacklist = "Zipcode included in Whitelist."
                })
            }

        }
        setErrors(error);
        return Object.keys(error).length > 0 ? false : true
    }

    const handleSubmit = () => {
        if (isFormValid()) {
            let deliveryService = {
                shipperId: shipperId,
                carrierId: service.carrierId,
                type: service.type,
                name: service.name,
                value: service.value,
                pickupBy: service.pickupBy,
                orderBy: service.orderBy,
                tatMin: service.tatMin,
                tatMax: service.tatMax,
                maxDistance: service?.maxDistance || null,
                sort: service.sort || 0,
                active: service.active,
                slackChannel: service?.slackChannel?.trim() || null,
                postalcode_whitelist: service?.postalcode_whitelist ? service?.postalcode_whitelist?.sort((a, b) => a - b) : null,
                postalcode_blacklist: service?.postalcode_blacklist ? service?.postalcode_blacklist?.sort((a, b) => a - b) : null
            }

            if (carrier?.alias?.toLowerCase() === 'fedex') {
                deliveryService.name = FedExService[service.value]
                deliveryService.type = 'FEDEX'
            } else if (carrier?.alias?.toLowerCase() === 'usps') {
                deliveryService.name = UspsService[service.value]
                deliveryService.type = 'USPS'
            } else if (carrier?.alias?.toLowerCase() === 'ups') {
                deliveryService.name = UpsService[service.value]
                deliveryService.type = 'UPS'
            }

            showSpinner(true);
            if (serviceId) {
                deliveryService.id = serviceId;
                if (!deliveryService?.orderBy) deliveryService.orderBy = deliveryService?.pickupBy
                API.graphql({ query: updateDeliveryService, variables: { input: deliveryService } })
                    .then(() => {
                        toast.success(`Delivery service has been updated`)
                        navigate(`/shipper-group/${shipperGroupId}/shipper/${shipperId}/service`);
                    })
                    .catch((error) => handleApiError(error))
                    .finally(() => showSpinner(false))
            } else {
                deliveryService.id = uniqid();
                API.graphql({ query: createDeliveryService, variables: { input: deliveryService } })
                    .then(() => {
                        toast.success(`Delivery service has been added`)
                        navigate(`/shipper-group/${shipperGroupId}/shipper/${shipperId}/service`);
                    })
                    .catch((error) => handleApiError(error))
                    .finally(() => showSpinner(false))
            }
        }
    }

    const handleDelete = () => {
        swal({
            text: 'Are you sure that you want to remove this service?',
            buttons: ['Cancel', 'Remove'],
            dangerMode: true,
        }).then(status => {
            if (status) {
                showSpinner(true)
                API.graphql({ query: deleteDeliveryService, variables: { input: { id: serviceId } } })
                    .then((data) => {
                        toast.success('Delivery service has been removed');
                        navigate(`/shipper-group/${shipperGroupId}/shipper/${shipperId}/service`);
                    })
            }
        })
    }

    const handleChange = (e) => setService({ ...service, [e.target.name]: e.target.value })

    const handleChnagePickupBy = (time) => {
        time = new Date(time[0]);
        setService({ ...service, pickupBy: moment(time).format('HH:mm') });
    }
    const handleChnageOrderBy = (time) => {
        time = new Date(time[0]);
        setService({ ...service, orderBy: moment(time).format('HH:mm') });
    }

    const handleCheckChange = async (e) => setService((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }));

    return (
        <>
            <PageHeader title={myShipper?.name} name={serviceId ? 'Edit Delivery Service' : 'Add Delivery Service'} className='container' />
            <Container>
                <Spinner display={spinner}>
                    <Form.Group>
                        <FormLabel required={true}>Courier</FormLabel>
                        <Form.Select name='carrierId' defaultValue={service?.carrierId || '-'} onChange={handleChange} isInvalid={!!errors?.carrierId}>
                            <option value='-' disabled>Select Courier</option>
                            {
                                carriers.length > 0 && carriers?.map((carrier) =>
                                    <option key={carrier?.id} value={carrier?.id}>{carrier?.name}</option>
                                )
                            }
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>{errors?.carrierId}</Form.Control.Feedback>
                    </Form.Group>

                    {
                        carrier?.alias?.toLowerCase() === 'fedex' && <Form.Group className='mt-4'>
                            <FormLabel required={true}>Service</FormLabel>
                            <Form.Select name='value' defaultValue={service?.value || '-'} onChange={handleChange} isInvalid={!!errors?.value}>
                                <option value='-' disabled>Select Service Type</option>
                                {
                                    Object.keys(FedExService).map((key) => <option value={key}>{FedExService[key]}</option>)
                                }
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>{errors?.value}</Form.Control.Feedback>
                        </Form.Group>
                    }

                    {
                        carrier?.alias?.toLowerCase() === 'usps' && <Form.Group className='mt-4'>
                            <FormLabel required={true}>Service</FormLabel>
                            <Form.Select name='value' defaultValue={service?.value || '-'} onChange={handleChange} isInvalid={!!errors?.value}>
                                <option value='-' disabled>Select Service Type</option>
                                {
                                    Object.keys(UspsService).map((key) => <option value={key}>{UspsService[key]}</option>)
                                }
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>{errors?.value}</Form.Control.Feedback>
                        </Form.Group>
                    }
                    {
                        carrier?.alias?.toLowerCase() === 'ups' && <Form.Group className='mt-4'>
                            <FormLabel required={true}>Service</FormLabel>
                            <Form.Select name='value' defaultValue={service?.value || '-'} onChange={handleChange} isInvalid={!!errors?.value}>
                                <option value='-' disabled>Select Service Type</option>
                                {
                                    Object.keys(UpsService).map((key) => <option value={key}>{UpsService[key]}</option>)
                                }
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>{errors?.value}</Form.Control.Feedback>
                        </Form.Group>
                    }

                    {
                        !['fedex', 'usps', 'ups'].includes(carrier?.alias?.toLowerCase()) && <>
                            <Form.Group className='mt-4'>
                                <FormLabel required={true}>Service Type</FormLabel>
                                <Form.Select name='type' defaultValue={service?.type || '-'} onChange={handleChange} isInvalid={!!errors?.type}>
                                    <option value='-' disabled>Select Service Type</option>
                                    {Object.keys(DeliveryService).map((key) => <option value={key}>{DeliveryService[key]}</option>)}
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>{errors?.type}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='mt-4'>
                                <FormLabel required={true}>Service Name</FormLabel>
                                <Form.Control type='text' name='name' placeholder='e.g. Same Day' value={service?.name} onChange={handleChange} isInvalid={!!errors?.name} />
                                <Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='mt-4'>
                                <FormLabel required={true}>Pickup By</FormLabel>
                                <div>
                                    <Flatpickr className='form-control service-type-picker' name='statusDate'
                                        value={service?.pickupBy || moment().format("HH:mm")}
                                        options={{
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            static: true
                                        }}
                                        onChange={handleChnagePickupBy}
                                    />
                                    <FormError error={errors?.pickupBy} />
                                </div>
                            </Form.Group>

                            <Form.Group className='mt-4'>
                                <FormLabel required={true}>Order By  <sann className="fs-5 text-muted">  (Shippers will be restricted from creating orders after this time) </sann> </FormLabel>
                                <div>
                                    <Flatpickr className='form-control service-type-picker' name='statusDate'
                                        value={service?.orderBy || service?.pickupBy || moment().format("HH:mm")}
                                        options={{
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            static: true
                                        }}
                                        onChange={handleChnageOrderBy}
                                    />
                                    <FormError error={errors?.orderBy} />
                                </div>
                            </Form.Group>

                            <Row>
                                <Col>
                                    <Form.Group className='mt-4'>
                                        <FormLabel required={true}>Min Delivery Turnaround Time</FormLabel>
                                        <Form.Select name='tatMin' defaultValue={service?.tatMin || '-'} onChange={handleChange} isInvalid={!!errors?.tatMin}>
                                            <option value='-'>Select Time</option>
                                            {Object.keys(DeliveryTat).map((key) => <option value={key}>{DeliveryTat[key]}</option>)}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{errors?.tatMin}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className='mt-4'>
                                        <FormLabel required={true}>Max Delivery Turnaround Time</FormLabel>
                                        <Form.Select name='tatMax' defaultValue={service?.tatMax || '-'} onChange={handleChange} isInvalid={!!errors?.tatMax}>
                                            <option value='-'>Select Time</option>
                                            {Object.keys(DeliveryTat).map((key) => <option value={key}>{DeliveryTat[key]}</option>)}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{errors?.tatMax}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className='mt-4'>
                                <FormLabel>Maximum Delivery Distance (mi)</FormLabel>
                                <Form.Control type='number' name='maxDistance' placeholder='e.g. 50 mi' value={service?.maxDistance} onChange={handleChange} isInvalid={!!errors?.maxDistance} />
                                <Form.Control.Feedback type='invalid'>{errors?.maxDistance}</Form.Control.Feedback>
                            </Form.Group>
                        </>
                    }

                    {!otherCarrierList.includes(carrier?.alias?.toLowerCase()) && (
                        <>
                            <label className='mt-4'>Zipcodes Whitelist</label>
                            <CreatableSelect isMulti key={service?.postalcode_whitelist}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: '1rem',
                                        borderWidth: '.2px',
                                        minHeight: '43px',
                                        minWidth: '120px'
                                    }),
                                }}
                                placeholder='Add allowed zipcodes'
                                onChange={(e) => {
                                    let zip = e.map(item => item.value)
                                    let rawZips = []
                                    for (let item of zip) {
                                        let splitZips = item.split(' ')
                                        for (let item of splitZips) {
                                            let trimmedZip = item.trim()
                                            if (trimmedZip.length !== 0) rawZips.push(trimmedZip)
                                        }
                                    }

                                    let sortedZipcodes = rawZips.sort((a, b) => a - b)
                                    let invalidWhitelistZips = []
                                    sortedZipcodes?.forEach(zip => {
                                        if (service?.postalcode_blacklist?.includes(zip)) invalidWhitelistZips.push(zip);
                                    });
                                    if (invalidWhitelistZips.length > 0) {
                                        setErrors({
                                            ...errors,
                                            whitelist: `Zipcode selected under blacklist.`
                                        })
                                    } else {
                                        delete errors?.whitelist
                                        setErrors(errors)
                                    }
                                    setService((prev => ({ ...prev, postalcode_whitelist: Array.from(new Set(sortedZipcodes)) })))
                                }}
                                defaultValue={service?.postalcode_whitelist ? service.postalcode_whitelist.map((item) => { return { label: item, value: item } }) : []}
                            />
                            <FormError error={errors?.whitelist} />
                        </>
                    )}
                    {!otherCarrierList.includes(carrier?.alias?.toLowerCase()) && (
                        <>
                            <label className='mt-4'>Zipcodes Blacklist</label>
                            <CreatableSelect isMulti key={service?.postalcode_blacklist}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: '1rem',
                                        borderWidth: '.2px',
                                        minHeight: '43px',
                                        minWidth: '120px'
                                    }),
                                }}
                                placeholder='Add blocked zipcodes'
                                onChange={(e) => {

                                    let zip = e.map(item => item.value)
                                    let rawZips = []
                                    for (let item of zip) {
                                        let splitZips = item.split(' ')
                                        for (let item of splitZips) {
                                            let trimmedZip = item.trim()
                                            if (trimmedZip.length !== 0) rawZips.push(trimmedZip)
                                        }
                                    }

                                    let sortedZipcodes = rawZips.sort((a, b) => a - b)
                                    let invalidBlackListZip = [];
                                    sortedZipcodes?.forEach(zip => {
                                        if (service?.postalcode_whitelist?.includes(zip)) invalidBlackListZip.push(zip);
                                    });
                                    if (invalidBlackListZip.length > 0) {
                                        setErrors({
                                            ...errors,
                                            blacklist: `Zipcode selected under whitelist.`
                                        })
                                    } else {
                                        delete errors?.blacklist
                                        setErrors(errors)
                                    }
                                    setService((prev => ({ ...prev, postalcode_blacklist: Array.from(new Set(sortedZipcodes)) })))
                                }}
                                defaultValue={service?.postalcode_blacklist ? service.postalcode_blacklist.map((item) => { return { label: item, value: item } }) : []}
                            />
                            <FormError error={errors?.blacklist} />
                        </>
                    )}

                    <Form.Group className='mt-4'>
                        <FormLabel >Slack Channel</FormLabel>
                        <Form.Control type='text' name='slackChannel' placeholder='e.g. Critical Alert' value={service?.slackChannel} onChange={handleChange} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group className='mt-4'>
                                <FormLabel>Sort Order</FormLabel>
                                <Form.Control type='number' name='sort' placeholder='e.g. 1' value={service?.sort || 0} onChange={handleChange} isInvalid={!!errors?.sort} />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className='mt-4'>
                                <FormLabel> Status</FormLabel>
                                <small className='form-text text-muted'>
                                    Disabling this will archive the selected Service Type.
                                </small>
                                <div className='row'>
                                    <div className='col-auto'>
                                        <div className='form-check form-switch'>
                                            <input className='form-check-input' type='checkbox' checked={service?.active} name='active' onChange={handleCheckChange} />
                                        </div>
                                    </div>
                                    <div className='col ms-n2'>
                                        {service?.active === true ?
                                            <small className='text-success h5'> Active</small> :
                                            <small className='text-danger h5'>Inactive</small>
                                        }
                                    </div>
                                </div>
                            </Form.Group >
                        </Col>

                    </Row>

                    <hr />
                    <button className='btn btn-dark' onClick={() => handleSubmit()}>Continue</button>

                    {
                        serviceId && service?.shipments?.items?.length === 0 &&
                        <button className='btn btn-link text-muted ms-2' onClick={() => handleDelete()}>Delete</button>
                    }

                    <Link to={-1} className='btn btn-link text-muted ms-2'> Cancel </Link>
                </Spinner>
            </Container>
        </>
    )
}

export default DeliveryServiceEditor;
