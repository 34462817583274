import React from 'react';
import { useLocation } from 'react-router-dom';

const GreenPhoxIcon = ({ isActive, name, icon }) => {
    const location = useLocation();

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {isActive ? (
                <>
                    <svg 
                        className={location.pathname === '/green-phox' && !location.pathname.includes('/green-phox') ? 'green-phox-img-active' : 'green-phox-img'} 
                        width='18px' 
                        height='18px' 
                        viewBox="0 0 188 231" 
                        fill={location.pathname === '/green-phox' && !location.pathname.includes('/green-phox') ? '#000000' : 'none'}
                        xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_104_1674)">
                            <path d="M187.61 98.5599C187.61 98.4099 187.6 98.2599 187.59 98.1099C187.7 96.5199 187.33 94.8799 186.44 93.4199C184.14 89.6499 179.21 88.4599 175.44 90.7599L172.64 92.4699V56.1699C172.64 48.3299 168.52 40.9999 161.88 37.0499L104.75 3.01988C97.9999 -1.00012 89.6099 -1.00012 82.8599 3.01988L25.7199 37.0599C19.0899 41.0099 14.9599 48.3399 14.9599 56.1799V92.4799L12.1699 90.7699C8.39992 88.4699 3.47992 89.6599 1.16992 93.4299C0.279921 94.8899 -0.0900792 96.5299 0.0199208 98.1199C0.0199208 98.2799 -7.92071e-05 98.4299 -7.92071e-05 98.5899L0.139921 143.37C0.0299208 143.94 -0.0200792 144.53 -7.92071e-05 145.14L0.159921 150.27C0.159921 150.36 0.179921 150.45 0.179921 150.54C-0.520079 153.78 0.829921 157.24 3.81992 159.07L85.7899 209.15V222.34C85.7899 226.76 89.3699 230.34 93.7899 230.34C98.2099 230.34 101.79 226.76 101.79 222.34V209.15L183.76 159.07C186.58 157.35 187.95 154.17 187.51 151.1C187.56 150.74 187.59 150.38 187.59 150.02V98.5699L187.61 98.5599ZM101.81 135.75V99.4899L156.65 65.9899V102.25L101.81 135.75ZM91.0499 16.7699C92.7799 15.7399 94.8399 15.7399 96.5599 16.7699L152.23 49.9399L93.7999 85.6299L35.3799 49.9299L91.0499 16.7599V16.7699ZM30.9699 65.9799L85.8099 99.4799V135.74L30.9699 102.24V65.9799ZM16.1599 147.84L16.0499 111.88L85.8099 154.5V190.39L16.1599 147.84ZM101.81 154.49L171.61 111.85V147.74L101.81 190.38V154.49Z" />
                        </g>
                        <defs>
                            <clipPath id="clip0_104_1674">
                                <rect width="187.61" height="230.33" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span style={{ marginLeft: '10px',fontSize: '16px' }}>{name}</span>
                </>
            ) : (
                <>
                <span style={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
                    <i className={`fe fe-${icon}`} style={{ fontSize: '18px' }}></i>
                    {name}
                </span>
            </>
            )}
        </div>
    );
};

export default GreenPhoxIcon;