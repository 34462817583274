import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Card, Row, Table } from 'react-bootstrap';
import { StatusFilter } from '../../components/EntityFilter';
import Spinner from '../../components/Spinner';
import { StatusBadge } from '../../components/StatusBadge';
import { TableDateFilter, TableKeywordSearch, TablePagination } from '../../components/TableFilter';
import { DELAY_TIMEOUT, handleApiError, toLocalDate, toLocalTime } from '../../helpers';
import { useNavigate } from 'react-router-dom';

const BoxShipmentsTable = ({ shipment }) => {

    const [spinner, ShowSpinner] = useState(false);
    const [shipments, setShipments] = useState([]);
    const [boxStatus, setBoxstatus] = useState();

    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    const [keyword, setKeyword] = useState('')
    const [dateFilters, setDateFilters] = useState(null);

    const navigate = useNavigate()


    useEffect(() => {
        setPageNumber(0);
        shipment?.boxId && getShipmentsByBoxID()
    }, [dateFilters, boxStatus, shipment])

    useEffect(() => {
        const delay = setTimeout(() => { setPageNumber(0); getShipmentsByBoxID() }, DELAY_TIMEOUT)
        return () => clearTimeout(delay)
    }, [keyword])

    useEffect(() => {
        shipment?.boxId && getShipmentsByBoxID()
    }, [pageNumber])


    const getShipmentsByBoxID = async () => {
        ShowSpinner(true);
        try {
            const apiName = 'api';
            const path = `/search/shipment?size=${10}&from=${pageNumber * 10}`;
            let init = {
                body: {
                    sort: [
                        {
                            "updatedAt": {
                                "order": "desc",
                                "unmapped_type": "date"
                            }
                        }
                    ],
                    query: {
                        bool: {
                            must: [
                                {
                                    match: {
                                        boxId: shipment?.boxId
                                    }
                                },
                                {
                                    match: {
                                        shipperId: shipment?.shipperId
                                    }
                                }

                            ]
                        }
                    }
                }
            };


            if (!keyword && dateFilters) {
                init.body.query.bool.must.push({
                    range: {
                        "expectedDeliveryTime": {
                            "gte": dateFilters?.fromDate,
                            "lte": dateFilters?.toDate
                        }
                    }
                })
            }

            if (keyword) {
                // while searching with keyword we dont want to sort
                delete init.body.sort

                let fields = [
                    "shipTo.name^3",
                    "shipTo.first_name^2",
                    "shipTo.last_name",
                    "shipTo.phone",
                    "customer.extId",
                    "extId",
                    "extTrackingNumber",
                    "shipTo.address.postalCode",
                    "items.number.keyword"
                ]
                if (+keyword) {
                    fields.push("number")
                }
                init.body.query.bool.must.push(
                    {
                        "multi_match": {
                            "query": keyword,
                            "fields": fields
                        }
                    }
                )
            }

            if (boxStatus?.value) init.body.query.bool.must.push({ match: { status: boxStatus?.value } });

            const data = await API.post(apiName, path, init);
            setShipments(data?.hits?.hits);
            setPageCount(Math.ceil(data?.hits?.total?.value / 10));
            ShowSpinner(false);
        } catch (error) {
            ShowSpinner(false);
            handleApiError(error)
        }

    }

    return (
        <Card>
            <Card.Header>
                <Row>
                    <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
                    <StatusFilter value={boxStatus} onChange={setBoxstatus} dontShowTitle={false} />
                    <TableDateFilter onChange={setDateFilters} startOf={14} />
                </Row>
            </Card.Header>

            <Spinner display={spinner}>
                <Table responsive size="sm" className="mb-0">
                    <thead>
                        <tr>
                            <th className="text-center">Shipment #</th>
                            <th>Recipient</th>
                            <th>Driver / Carrier</th>
                            <th className="text-center">Delivery Service</th>
                            <th className="text-center">Entered At</th>
                            <th className="text-center">Due By</th>
                            <th className="text-center">Shipment Status</th>

                        </tr>
                    </thead>
                    <tbody>
                        {shipments.map((shipment) => {
                            return (
                                <tr
                                    key={shipment?._source?.id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        navigate(`/box/details/${shipment?._source?.id}`);
                                    }}
                                >
                                    <td className="text-center">
                                        {shipment?._source?.number}
                                        {shipment?._source?.batch_number > 0 && (
                                            <div className="small text-muted">
                                                Batch #{shipment?.batch_number}
                                            </div>
                                        )}
                                    </td>


                                    <td>
                                        {shipment?._source?.shipTo?.name}
                                        <div className="small text-muted">
                                            {shipment?._source?.shipTo?.address?.city},{" "}
                                            {shipment?._source?.shipTo?.address?.state}
                                        </div>
                                    </td>

                                    <td>
                                        <div>{shipment?._source?.driver?.name || "Unassigned"}</div>
                                        <div className="text-small text-muted">
                                            {shipment?._source.carrier?.alias}
                                        </div>
                                    </td>

                                    <td className="text-center">  {shipment?._source?.deliveryService?.name} </td>

                                    <td className="text-center">
                                        {toLocalTime(
                                            shipment?._source?.createdTime,
                                            shipment?._source?.shipFrom?.timezone?.id
                                        )}
                                        <div className="small text-muted">
                                            {toLocalDate(
                                                shipment?._source?.createdTime,
                                                shipment?._source?.shipFrom?.timezone?.id
                                            )}
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        {shipment?._source?.expectedDeliveryTime && (
                                            <>
                                                {toLocalTime(shipment?._source?.expectedDeliveryTime, shipment?._source?.shipFrom?.timezone?.id)}
                                                <div className="small text-muted">{toLocalDate(shipment?._source?.expectedDeliveryTime, shipment?._source?.shipFrom?.timezone?.id)}</div>
                                            </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        <StatusBadge status={shipment?._source?.status} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={8} />

                </Table>
            </Spinner>
        </Card>

    )

}



export default BoxShipmentsTable