import React from 'react';
import { Button, Col } from 'react-bootstrap';

export const Card = ({ title, children, size, bodyPadding, buttonName, onClick, moreDetails }) => {
	return (
		<div className={size}>
			<div className='card'>
				<div className='card-header'>
					<h4 className='card-header-title'>{title}</h4>
					{
						buttonName &&
						<Col className='col-auto'>
							{
								<Button className='btn btn-sm btn-light' onClick={onClick}>{buttonName}</Button>
							}
						</Col>
					}
					{
						moreDetails &&
						<Col className='col-auto'>
							{
								<h6 className='card-header-title'> {moreDetails}</h6>
							}
						</Col>
					}


				</div>
				<div className={`card-body ${bodyPadding}`}>
					{children}
				</div>
			</div>
		</div>
	);
};
export const MultiButtonCard = ({ title, children, size, bodyPadding, buttons }) => {
	return (
		<div className={size}>
			<div className='card'>
				<div className='card-header'>
					<h4 className='card-header-title'>{title}</h4>
					{
						buttons &&
						<Col className='col-auto'>
							{
								buttons?.map((button, index) => { return <Button key={index} className={`btn btn-sm btn-light ${index === 1 ? 'mx-2' : ''}`} onClick={button.onClick}>{button.buttonName}</Button> })

							}
						</Col>
					}
				</div>
				<div className={`card-body ${bodyPadding}`}>
					{children}
				</div>
			</div>
		</div>
	);
};

export const StatCard = ({ title, value, spanValue, icon, color, child, childName, spanBadgeColor }) => {
	return (
		<div className='col-sm'>
			<div className='card'>
				<div className='card-body'>
					<div className='row align-items-center gx-0'>
						<div className='col'>
							<h6 className='text-uppercase text-muted mb-2'>{title}</h6>
							<span className='h3 mb-0'>{value}</span>
							{spanValue && <span className={`badge ${spanBadgeColor || "bg-secondary-soft"}  mt-n1 mx-2`}>{spanValue}</span>}
							{child && childName && <div>
								<p className='text-muted mb-0' >{`${childName} ${child}`}</p>
							</div>}
						</div>
						<div className='col-auto'>
							<span className={`h2 fe fe-${icon} ${color ? color : 'text-muted'}  mb-0`}></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}