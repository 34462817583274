/* eslint-disable react-hooks/exhaustive-deps */

import { MapView } from '@aws-amplify/ui-react-geo';
import { API } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DriverMarker, DriverRouteMarker } from '../../components/MapMarker';
import Spinner from '../../components/Spinner';
import { PAGE_TITLE } from '../../helpers';
import ReactGA from "react-ga4"

const onShipmentUpdate = /* GraphQL */ `
  subscription ShipmentSubscription ($filter: ModelSubscriptionShipmentFilterInput) {
    onUpdateShipment(filter: $filter) {
      id
	  driverId
      status
	}
  }
`;

const onShipmentRouteUpdate = /* GraphQL */ `
  subscription ShipmentRouteSubscription ($filter: ModelSubscriptionShipmentRouteFilterInput) {
    onUpdateShipmentRoute(filter: $filter) {
      id
	  routeSequence
      routeDistance
      routeDuration
	}
  }
`;

const onUserLocationUpdate = /* GraphQL */ `
  subscription UserLocationSubscription ($filter: ModelSubscriptionUserLocationFilterInput) {
    onUpdateUserLocation(filter: $filter) {
      	id
	  	latitude
    	longitude
    }
  }
`;

const shipmentsByDriverAndStatusQuery = /* GraphQL */ `
  query MyQuery($driverId: ID!) {
    shipmentsByDriverAndStatus(driverId: $driverId, status: {eq: "OUT_FOR_DELIVERY"}) {
		items {
			id
			number
			status
			shipTo
			driver {
				id
				name
        		location {
					latitude
					longitude
				}
      		}
			route {
				routeSequence
				routeDuration
				routeDistance
			}
		}
  	}
  }
`;

const DriverTracker = () => {


	const { id } = useParams();
	const mapRef = useRef();
	const mapState = { latitude: 37.7061332, longitude: -101.4802194, zoom: 4 }

	const [spinner, showSpinner] = useState(true);
	const [shipments, setShipments] = useState({})
	const [driver, setDriver] = useState()

	useEffect(() => {
		getShipments();
		ReactGA.send({
			hitType: "pageview",
			page: `/map/driver/${id}`,
		})
		document.title = `Driver Tracker ${PAGE_TITLE}`;
	}, []);

	const getShipments = async () => {
		try {
			const { data } = await API.graphql({
				query: shipmentsByDriverAndStatusQuery, variables: { driverId: id }
			})

			let shipments = {}
			for (var shipment of data.shipmentsByDriverAndStatus.items) {
				shipment.shipTo = JSON.parse(shipment.shipTo)
				shipments[shipment.id] = shipment
			}

			setShipments(shipments);
			setDriver(data.shipmentsByDriverAndStatus.items[0].driver);

			API.graphql({ query: onShipmentUpdate }).subscribe({
				next: (response) => {
					const shipment = response?.value?.data?.onUpdateShipment;
					if (shipments[shipment.id]) {
						shipments[shipment.id].status = shipment.status
						setShipments(shipments);
					}
				},
				error: (error) => console.error('onShipmentUpdate', error),
			});

			API.graphql({ query: onShipmentRouteUpdate }).subscribe({
				next: (response) => {
					const route = response?.value?.data?.onUpdateShipmentRoute;
					if (shipments[route.id]) {
						shipments[route.id].route = route;
						setShipments(shipments);
					}
				},
				error: (error) => console.error('onShipmentRouteUpdate', error),
			});

			API.graphql({ query: onUserLocationUpdate, variables: { filter: { id: { eq: id } } } }).subscribe({
				next: (response) => {
					setDriver((prev) => ({ ...prev, location: response?.value?.data?.onUpdateUserLocation }))
				},
				error: (error) => console.error('onUserLocationUpdate', error)
			});
		} catch (error) {
			console.error('error', error)
		}

		showSpinner(false);
	}

	return (
		<>
			<Spinner display={spinner}>
				<MapView ref={mapRef} initialViewState={mapState}>
					{
						shipments && Object.keys(shipments).map((id) => <DriverRouteMarker key={id} shipment={shipments[id]} />)
					}

					<DriverMarker driver={driver} />
				</MapView>
			</Spinner>
		</>
	)
}

export default DriverTracker;