/* eslint-disable react-hooks/exhaustive-deps */

import { StorageManager } from '@aws-amplify/ui-react-storage';
import { API, Storage } from 'aws-amplify';
import { phone } from 'phone';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactGA from "react-ga4";
import { toast } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import uniqid from 'uniqid';
import { AddressField } from '../../components/Address';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { getLocationTimezone, handleApiError } from '../../helpers';
import { storeShipperGroup } from '../../stores/slice';

const getShipperGroup = /* GraphQL */ `
  query GetShipperGroup($id: ID!) {
    getShipperGroup(id: $id) {
        id
        name
        alias
        phone
        email
        image
        active
        address {
          address1
          address2
          city
          state
          postalCode
        }
    }
  }
`;

const createShipperGroup = /* GraphQL */ `
  mutation CreateShipperGroup($input: CreateShipperGroupInput!) {
    createShipperGroup(input: $input) {
      id
      name
      image
    }
  }
`;

const updateShipperGroup = /* GraphQL */ `
  mutation UpdateShipperGroup($input: UpdateShipperGroupInput!) {
    updateShipperGroup(input: $input) {
      id
      name
      image
    }
  }
`;

const ShipperGroupEditor = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { shipperGroupId } = useParams();

    const [spinner, showSpinner] = useState(false);
    const [errors, setErrors] = useState();
    const [shipperGroup, setShipperGroup] = useState({
        id: uniqid(),
        name: '',
        alias: '',
        email: '',
        phone: '',
        image: '',
        active: true,
        address: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            location: {
                latitude: 0,
                longitude: 0
            }
        },
        timezone: {}
    });

    useEffect(() => {
        shipperGroupId && getShipperGroupById()
        ReactGA.send({
            hitType: "pageview",
            page: `${shipperGroupId ? `/shipper-group/${shipperGroupId}/edit` : "/shipper-group/add"} `,
        })
    }, []);

    const getShipperGroupById = () => {
        showSpinner(true)
        API.graphql({ query: getShipperGroup, variables: { id: shipperGroupId } })
            .then((response) => setShipperGroup(response?.data?.getShipperGroup))
            .catch((error) => handleApiError(error))
            .finally(() => showSpinner(false));
    }

    const isFormValid = () => {
        const error = {};

        if (!shipperGroup?.name?.trim()) error['name'] = 'Name is required'
        if (!shipperGroup?.email?.trim()) error['email'] = 'Email is required'
        if (!phone(shipperGroup?.phone?.trim()).isValid) error['phone'] = 'Please enter a valid phone number';
        if (!shipperGroup?.address?.address1?.trim()) error['address1'] = 'Address Line 1 is required';
        if (!shipperGroup?.address?.postalCode?.trim() && !error['address1']) error['address1'] = 'please enter the address with the postal code';

        setErrors(error);

        return Object.keys(error).length === 0;
    };

    const handleChange = async (e) => setShipperGroup((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    const handleAddress2Change = (e) => setShipperGroup((prevState) => ({ ...prevState, address: { ...prevState.address, address2: e.target.value } }));
    const handlePhoneChange = (phone) => setShipperGroup((prevState) => ({ ...prevState, phone: `+${phone}` }));

    const handleAddressSelect = async ({ value }) => {
        const value_address = value?.address
        if (!value_address?.houseNumber && !value_address?.street) return toast.error("Please enter a valid address")

        let address1 = `${value_address?.houseNumber} ${value_address?.street}`;
        let city = value_address?.city;
        let state = value_address?.stateCode;
        let postalCode = value_address?.postalCode.split("-")[0];
        let address2 = ''

        let location = {};
        if (value?.position.lat && value?.position.lng) {
            location.latitude = value?.position.lat;
            location.longitude = value?.position.lng;
        }

        let timezone = await getLocationTimezone(location);
        setShipperGroup({
            ...shipperGroup,
            address: {
                address1,
                address2,
                postalCode,
                city,
                state,
                location,
            },
            timezone: timezone
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isFormValid()) {
            showSpinner(true);
            !shipperGroup.image && delete shipperGroup.image
            if (!shipperGroupId) shipperGroup.active = true;
            const query = shipperGroupId ? updateShipperGroup : createShipperGroup;
            API.graphql({ query: query, variables: { input: shipperGroup } })
                .then((response) => {
                    if (shipperGroupId) {
                        toast.success(`${shipperGroup?.name} has been updated.`)
                        dispatch(storeShipperGroup(response?.data?.updateShipperGroup));
                        navigate(`/shipper-group/${shipperGroupId}/overview`);
                    }
                    else {
                        toast.success(`${shipperGroup?.name} has been added.`)
                        dispatch(storeShipperGroup(response?.data?.createShipperGroup));
                        navigate(`/shipper-group/${response?.data?.createShipperGroup?.id}/overview`);
                    }
                })
                .catch((error) => handleApiError(error))
                .finally(() => showSpinner(false))
        }
    };

    const processFile = ({ file }) => {
        const ext = file.name.split('.').pop();
        return { file, key: `${uniqid()}.${ext}` };
    };

    const onUploadSuccess = ({ key }) => {
        Storage.get(key).then(url => {
            setShipperGroup(prevAttachment => ({ ...prevAttachment, image: url.split('?')[0] }))
        });
    };

    return (
        <>
            <PageHeader name={shipperGroupId ? 'Edit Shipper Group' : 'New Shipper Group'} classname={'container'} />

            <Spinner display={spinner}>
                <Container>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <FormLabel required={true}>Name </FormLabel>
                            <Form.Control type='text' name='name' placeholder='e.g. ABC Specialty Pharmacy' value={shipperGroup?.name || ''} onChange={handleChange} isInvalid={!!errors?.name} />
                            <Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mt-4'>
                            <FormLabel>Alias</FormLabel>
                            <Form.Control type='text' name='alias' placeholder='e.g. abc-specialty' value={shipperGroup.alias || ''} onChange={handleChange} />
                        </Form.Group>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className='mt-4'>
                                    <FormLabel required={true}>Email </FormLabel>
                                    <Form.Control type='email' name='email' placeholder='e.g. john@pharmacy.com' value={shipperGroup.email || ''} onChange={handleChange} isInvalid={!!errors?.email} />
                                    <Form.Control.Feedback type='invalid'>{errors?.email}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className='mt-4'>
                                    <FormLabel required={true}>Phone </FormLabel>
                                    <Form.Control type='phone' name='phone' className='d-none' placeholder='e.g. 998-776-5543' value={shipperGroup.phone || ''} onChange={handleChange} isInvalid={!!errors?.phone} />
                                    <PhoneInput country='us' onlyCountries={['us']} value={shipperGroup.phone} placeholder='e.g. 998-776-5543' onChange={handlePhoneChange} isInvalid={!!errors?.phone} />
                                    <Form.Control.Feedback type='invalid'>{errors?.phone}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col lg={6}>
                                <Form.Group>
                                    <FormLabel required={true}>Address Line 1 </FormLabel>
                                    <AddressField name='address.address1' value={shipperGroup.address?.address1}
                                        error={errors?.address1} isInvalid={!!errors?.address1}
                                        onChange={handleAddressSelect} />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <FormLabel>Address Line 2</FormLabel>
                                    <Form.Control type='text' name='address2' placeholder='e.g. Suite 100' value={shipperGroup?.address?.address2 || ''} onChange={handleAddress2Change} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col lg={4}>
                                <Form.Group>
                                    <FormLabel required={true}>City</FormLabel>
                                    <Form.Control type='text' name='city' placeholder='e.g. Seattle' value={shipperGroup?.address?.city || ''} onChange={handleChange} disabled />
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group>
                                    <FormLabel required={true}>State</FormLabel>
                                    <Form.Control type='text' name='state' placeholder='e.g. WA' value={shipperGroup?.address?.state || ''} onChange={handleChange} disabled />
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group>
                                    <FormLabel required={true}>Postal Code</FormLabel>
                                    <Form.Control type='text' name='postalCode' placeholder='e.g. 98105' value={shipperGroup?.address?.postalCode} onChange={handleChange} disabled />
                                </Form.Group>
                            </Col>

                            <Col lg={12} className='mt-5'>
                                <FormLabel>Upload Logo</FormLabel>
                                <StorageManager
                                    acceptedFileTypes={['.png', '.jpeg', '.jpg']}
                                    accessLevel='public' maxFileCount={3}
                                    showImages={false} path={`ShipperGroup/${shipperGroup?.id}/`}
                                    processFile={processFile} onUploadSuccess={onUploadSuccess}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Button type='submit' className='btn btn-dark ms-2'> Continue </Button>
                        <Link to={shipperGroupId ? `/shipper-group/${shipperGroupId}/overview` : `/shipper-group`} className='btn btn-link text-muted ms-2'> Cancel </Link>
                    </Form>
                </Container>
            </Spinner>
        </>
    )
}

export default ShipperGroupEditor;